/* sassForm
----------------------------------------------------------------- */
@use "../../global" as g;

.sass-form {
  padding: 8rem 0;

  &__ttl {
    text-align: center;
    font-size: g.$fs40;
    font-weight: bold;
  }

  &__inner {
    display: flex;
    justify-content: center;
    gap: 2.4rem;
    margin-top: 6rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
  }

  &__item-ttl {
    font-size: g.$fs24;
  }

  &__txt-area {
    padding: 1rem;
    display: block;
    width: 50rem;
    height: 50rem;
    font-size: g.$fs14;
    border: 1px solid black;
    border-radius: 10px;

    &--html {
      @extend .sass-form__txt-area;
    }

    &--sass {
      @extend .sass-form__txt-area;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4rem auto 0;
    font-size: g.$fs24;
    width: 20rem;
    height: 6rem;
    border: 1px solid black;
    border-radius: 10px;
    transition: 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
}
